import * as React from "react"
import styled, { keyframes } from "styled-components"
import StyledArtDirectedBackground from "../components/StyledArtDirectedBackground"
import Layout from "../components/Layout"
import Wave from "../components/Wave"
import Seo from "../components/Seo"
import LatestPost from "../components/LatestPost"
import FeaturedBook from "../components/FeaturedBook"

const Hero = props => {
  return (
    <HeroSection>
      <MainImage>
        <MainTitle>
          Joss Cannon
          <br />
          <Chevron
            width="50px"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12.02 4.85"
          >
            <path
              d="M199.94,214.46c.3.06.52.13.52.13s.14.18.28.44a1.48,1.48,0,0,0,.37.37.5.5,0,0,1,.19.35l-.68.1a4.47,4.47,0,0,0-.91.29l-.89.36a3.06,3.06,0,0,1-.66.31l.11.35a3.82,3.82,0,0,0-1,.48l-.43.27-.19.28-.19.32c-.06.12-.3.24-.72.35v.1c.17.11.25.2.25.3l-.06,0a3.33,3.33,0,0,1-.83-.24l-.47-.39a18,18,0,0,1-1.73-1.41,3.87,3.87,0,0,0-.91-.59l-.1-.07a.82.82,0,0,0-.45-.12,1.59,1.59,0,0,0-.5-.22,1.85,1.85,0,0,1-.76-.32,5,5,0,0,1-.91-1.07,1.52,1.52,0,0,1,.31-.42,5.71,5.71,0,0,1,.72.41l.62.18a1.73,1.73,0,0,1,.25.21l.27,0c.24.28.41.45.49.53a10.86,10.86,0,0,0,1,.69c.34.28.61.48.8.61l1.42.56a2.74,2.74,0,0,0,.56-.39l.43-.15c.11-.1.3-.27.33-.31a1.84,1.84,0,0,1,.18-.19l.54-.35.51-.49c.5-.28,1-.35,1.15-.49l.37-.15c.15-.13.49-.09.42-.15s.07-.26.07-.29S199.81,214.55,199.94,214.46Z"
              transform="translate(-189.28 -214.44)"
              fill="#fff"
            />
          </Chevron>
        </MainTitle>
      </MainImage>
      <Wave />
    </HeroSection>
  )
}

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <HomeSection>
        <LatestPost />
        <FeaturedBook />
      </HomeSection>
    </Layout>
  )
}

export default IndexPage

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 110vh;
  background-size: cover;
  overflow: hidden;
`

const MainImage = styled(StyledArtDirectedBackground)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
`

const MainTitle = styled.h1`
  margin-top: 75vh;
  text-align: center;
  font-size: 14vw;
  color: white;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    font-size: 5rem;
    margin-top: 80vh;
  }
`

const bounce = keyframes`
    0% {
      transform: translateY(0);
    }
    11.11111% {
      transform: translateY(0);
    }
    22.22222% {
      transform: translateY(-5px);
    }
    27.77778% {
      transform: translateY(0);
    }
    33.33333% {
      transform: translateY(-3px);
    }
    44.44444% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
`

const Chevron = styled.svg`
  margin-top: 1.5rem;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-name: ${bounce};
  animation-duration: 3.6s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-top: 3rem;
  }
`

const HomeSection = styled.section`
  background-color: ${({ theme }) => theme.bgLight};
  width: 100%;
  padding-top: 2rem;
  color: ${({ theme }) => theme.primaryDark};
`
