import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"
import Container from "../components/Container"

const LatestPost = () => {
  const { postData } = useStaticQuery(
    graphql`
      query LatestBlogPost {
        postData: allSanityPost(
          sort: { fields: publishedAt, order: DESC }
          filter: { publishedAt: { ne: null } }
          limit: 1
        ) {
          nodes {
            slug {
              current
            }
            title
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            _rawBody(resolveReferences: { maxDepth: 1 })
            publishedAt(formatString: "D MMM")
          }
        }
      }
    `
  )
  const post = postData.nodes[0]

  // Trim the body to fit: max 5 paragraphs or 400 characters
  let i = 0
  let totalChars = 0
  while (i < post._rawBody.length && i < 5 && totalChars < 400) {
    totalChars += post._rawBody[i].children[0].text.length
    i++
  }
  post.body = post._rawBody.slice(0, i - 1)

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  return (
    <Container>
      <SectionTitle>Latest Blog</SectionTitle>
      <SectionContainer>
        <ImageContainer>
          <Link to={"/blog/" + post.slug.current}>
            {post.mainImage != null ? (
              <StyledImage
                image={post.mainImage.asset.gatsbyImageData}
                alt={post.title}
              />
            ) : null}
          </Link>
        </ImageContainer>
        <CardText>
          <StyledLink to={"/blog/" + post.slug.current}>
            <PostTitle>{post.title}</PostTitle>
          </StyledLink>
          <BlockContent blocks={post.body} serializers={serializers} />
          <FlexContainer>
            <Link to={"/blog/" + post.slug.current}>
              <LinkSpan>Read more</LinkSpan>
            </Link>
            <Link to={"/blog"}>
              <LinkSpan>All posts</LinkSpan>
            </Link>
          </FlexContainer>
        </CardText>
      </SectionContainer>
    </Container>
  )
}

export default LatestPost

const SectionContainer = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row-reverse;
  }
`

const SectionTitle = styled.h2`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    margin-bottom: 2.5rem;
  }
`

const ImageContainer = styled.article`
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 480px;
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 35vh;
  border-radius: 6px;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    max-height: 60vmin;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    max-height: 35vh;
  }
`

const CardText = styled.article`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.primaryDark};
  padding: 1.5rem 4px 0 4px;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 4rem 0 0;
  }
`

const PostTitle = styled.h3`
  margin-bottom: 0.5rem;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.accent};
  text-decoration: none;
  cursor: pointer;
`

const LinkSpan = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.accent};

  & :hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`
