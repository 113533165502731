import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const BookImage = styled(GatsbyImage)`
  width: 70%;
  margin: 0 auto 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 20vw;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

export default BookImage
