import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import Container from "../components/Container"
import BookImage from "../components/BookImage"

const FeaturedBook = () => {
  const { bookData } = useStaticQuery(graphql`
    query FeaturedBook {
      bookData: allSanityBook(
        sort: { fields: _updatedAt, order: DESC }
        limit: 1
      ) {
        nodes {
          altText
          slug {
            current
          }
          title
          excerpt
          coverImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  const book = bookData.nodes[0]

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  return (
    <ComponentContainer>
      <Container>
        <SectionTitle>Upcoming Books</SectionTitle>
        <SectionContainer>
          <ImageContainer>
            <Link to="/books">
              {book.coverImage != null ? (
                <BookImage
                  image={book.coverImage.asset.gatsbyImageData}
                  alt={book.altText}
                />
              ) : null}
            </Link>
          </ImageContainer>
          <CardText>
            <StyledLink to="/books">
              <Title>{book.title}</Title>
            </StyledLink>
            <BlockContent blocks={book.excerpt} serializers={serializers} />
            <FlexContainer>
              <Link to="/books">
                <LinkSpan>Read more</LinkSpan>
              </Link>
            </FlexContainer>
          </CardText>
        </SectionContainer>
      </Container>
    </ComponentContainer>
  )
}

export default FeaturedBook

const ComponentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 3rem 0 2rem 0;
  background-color: ${({ theme }) => theme.bgSecondaryLight};
`

const SectionContainer = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
  }
`

const ImageContainer = styled.article`
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    width: 50%;
    max-width: 480px;
    text-align: left;
  }
`

const SectionTitle = styled.h2`
  margin-bottom: 2rem;
`

const CardText = styled.article`
  width: 100%;
  color: ${({ theme }) => theme.primaryDark};
  padding: 0 4px 0 4px;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.accent};
  text-decoration: none;
  cursor: pointer;
`

const Title = styled.h3`
  margin-bottom: 0.5rem;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`

const LinkSpan = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.accent};

  & :hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`
