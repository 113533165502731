import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const ArtDirectedBackground = ({ className, children }) => {
  const { desktopImage } = useStaticQuery(graphql`
    query BgImage {
      desktopImage: sanitySingleImage(Id: { eq: "hero_desktop" }) {
        theImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      mobileImage: sanitySingleImage(Id: { eq: "hero_mobile" }) {
        theImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const deskImg = convertToBgImage(getImage(desktopImage.theImage.asset)).fluid
  // const mobImg = convertToBgImage(getImage(mobileImage.theImage.asset)).fluid

  // const sources = [
  //   mobImg,
  //   {
  //     ...deskImg,
  //     media: `(min-width: 800px)`,
  //   },
  // ]
  const sources = [deskImg]

  return (
    <BackgroundImage
      Tag={`section`}
      id={`media-test`}
      className={className}
      fluid={sources}
      alt="Merman underwater"
    >
      <div>{children}</div>
    </BackgroundImage>
  )
}

const StyledArtDirectedBackground = styled(ArtDirectedBackground)`
  /* width: 100%; */
  height: 110vh;
  /* You should set a background-size as the default value is "cover"! */
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
`

export default StyledArtDirectedBackground
